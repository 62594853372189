import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Container, Card, Button, Row, Col } from 'react-bootstrap'

import MainLayout from '../../components/main-layout'
import Seo from '../../components/seo'

import ReactMarkdown from 'react-markdown'

import { animate, dontUnderline } from '../../styles/common.module.css'
import { postImage, markdownImage } from './news.module.css'


const NewsPage = ({ data }) => {

    return (
        <MainLayout pageTitle={data.strapiNewsPost.title + " - News"}>

            <Seo title={data.strapiNewsPost.title + " - News"} description="News article by ai03 Design Studio" imageUrl={data.strapiNewsPost.image && data.strapiNewsPost.image.localFile.childImageSharp.fixed.src}/>

            <Container className="text-center">
                <Row xs={1} md={2} lg={3} xl={4} className="justify-content-center mt-4 mb-3">
                    <Col>
                        <Button className="w-100" as={Link} to={"/news"}>Return to all news</Button>
                    </Col>
                </Row>

                <h1 className={`mb-2 ${animate}`}>{data.strapiNewsPost.title}</h1>
                <p className={`mb-3 text-muted ${animate}`}>{data.strapiNewsPost.created_at}</p>
                {
                    data.strapiNewsPost.image &&
                    <GatsbyImage image={data.strapiNewsPost.image.localFile.childImageSharp.gatsbyImageData} alt="" className={`rounded-1 mb-5 ${animate}`} />
                }
                <ReactMarkdown className={`mb-5 text-start ${animate} ${markdownImage}`} children={data.strapiNewsPost.content} />
                {
                    data.strapiNewsPost.relatedboards.length > 0 &&
                    <Card className={`rounded shadow mb-4 ${animate}`}>
                        <Card.Header>
                            <p className="mb-0">Projects related to this article</p>
                        </Card.Header>
                        <Row xs={2} md={3} lg={4} className="justify-content-center pt-3 pb-3">
                            {
                                data.strapiNewsPost.relatedboards.map(board => (
                                    <Link key={board.id} as={Col} to={"/projects/" + board.slug} className={`p-0 p-1 ${animate} ${dontUnderline}`}>
                                        <Card className="rounded shadow-s p-0 h-100">
                                            <Card.Img as={GatsbyImage} image={board.cover.localFile.childImageSharp.gatsbyImageData} alt="" variant="top" className={`rounded-top ${postImage}`} />
                                            <Card.Body className="text-center">
                                                <Card.Title as="h3" className="mb-0">{board.boardname}</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Link>

                                ))
                            }
                        </Row>

                    </Card>
                }

            </Container>


        </MainLayout>
    )
}

export const query = graphql`
query MyQuery($id: String) {
    strapiNewsPost(id: {eq: $id}) {
        id
        title
        content
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1.618
                transformOptions: { fit: COVER, cropFocus: CENTER }
                placeholder: BLURRED
                quality: 98
              )
              fixed(width: 800, toFormat: JPG, quality: 95) {
                src
              }
            }
          }
        }
        slug
        relatedboards {
          id
          boardname
          cover {
            localFile {
              childImageSharp {
                gatsbyImageData(
                    aspectRatio: 1.618
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                    placeholder: BLURRED
                    width: 500
                    quality: 90
                )
              }
            }
          }
          slug
        }
        created_at(formatString: "YYYY-MM-DD")
      }
  }  
`

export default NewsPage